.ring {
  animation: pulse 1.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes pulse {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
