@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url('/fonts/CircularStd-Book.woff2') format('woff2'),
    url('/fonts/CircularStd-Book.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src:
    url('/fonts/CircularStd-BookItalic.woff2') format('woff2'),
    url('/fonts/CircularStd-BookItalic.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src:
    url('/fonts/CircularStd-Medium.woff2') format('woff2'),
    url('/fonts/CircularStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src:
    url('/fonts/CircularStd-MediumItalic.woff2') format('woff2'),
    url('/fonts/CircularStd-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src:
    url('/fonts/CircularStd-Bold.woff2') format('woff2'),
    url('/fonts/CircularStd-Bold.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: italic;
  font-weight: 600;
  font-display: block;
  src:
    url('/fonts/CircularStd-BoldItalic.woff2') format('woff2'),
    url('/fonts/CircularStd-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src:
    url('/fonts/CircularStd-Black.woff2') format('woff2'),
    url('/fonts/CircularStd-Black.woff') format('woff');
}
@font-face {
  font-family: 'CircularStd';
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src:
    url('/fonts/CircularStd-BlackItalic.woff2') format('woff2'),
    url('/fonts/CircularStd-BlackItalic.woff') format('woff');
}
@font-face {
  font-family: 'QuincyCF';
  font-style: normal;
  font-weight: 400, 500, 600, 700, 800, 900;
  font-display: block;
  src:
    url('/fonts/QuincyCF-BlackItalic.woff2') format('woff2'),
    url('/fonts/QuincyCF-BlackItalic.woff') format('woff');
}
