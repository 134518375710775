.container p + div.callout {
  margin-top: -12px;
}

.container h2 + p {
  margin-top: 12px;
}

.container h2 + ul {
  margin-top: 20px;
}

.container h3 + ul {
  margin-top: 12px;
}

.container p + p {
  margin-top: 24px;
}

.container ul + p {
  margin-top: 32px;
}

.container p + ul {
  margin-top: -4px;
}
