.decoration:before {
  content: ' ';
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-image: var(--decoration-pattern-image);
}
